body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

.auth-input-container {
  display: flex;
  justify-content: center;
}

.auth-input-tablet {
  width: 50px;
  height: 50px;
  padding: 0;
  font-size: 24px;
  text-align: center;
  margin-right: 12px;
  text-transform: uppercase;
  color: #494949;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  background: #fff;
  background-clip: padding-box;
}

.auth-input {
  width: 45px;
  height: 45px;
  padding: 0;
  font-size: 26px;
  text-align: center;
  margin-right: 8px;
  text-transform: uppercase;
  color: #494949;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  background: #fff;
  background-clip: padding-box;
}

* > .auth-input-tablet:last-child,
* > .auth-input:last-child {
  margin-right: 0px;
}

.time-dropdown {
  font-size: 24px;
  font-weight: bold;
  color: white;
  text-align: center;
  border: 3px;
  border-color: white;
  margin: 10px;
  border-radius: 5;
  padding: 20px 60px 20px 40px;
}

.time-dropdown-icon {
  top: 42;
  right: 40;
}
